import React from "react"
import BasicPage from "../components/PageWrapper/BasicPage"

const Widerrufsrecht = (props) => {
  return (
    <>
      <BasicPage
        title="Widerrufsrecht"
        location={props.location}
        noindex={true}
      >
        <section className="container bg-white py-8 md:px-6 mx-auto max-w-3xl">
          <p>
            Der Privatnutzer hat das Recht binnen 14 Tagen ab Vertragsschluss
            ohne Angabe von Gründen diesen Vertrag zu widerrufen.
          </p>
          <p>
            Um Ihr Widerrufsrecht auszuüben, muss der Nutzer den Anbieter <br />
            (smartificate GmbH, Schulstraße 28, 22113 Oststeinbek,
            support@smartificate.de)
            <br /> mittels einer eindeutigen Erklärung (z. B. ein mit der Post
            versandter Brief, oder E-Mail) über Ihren Entschluss, diesen Vertrag
            zu widerrufen, informieren. Hierfür kann das beigefügte
            Muster-Widerrufsformular verwendet werden, das jedoch nicht
            vorgeschrieben ist.
          </p>
          <a
            href="/smartificate_Widerrufsbelehrung.pdf"
            target="_blank"
            className="underline text-xl text-gray-800 text-bold hover:text-brand-green"
          >
            Muster Widerrufsformular
          </a>
        </section>
      </BasicPage>
    </>
  )
}

export default Widerrufsrecht
